









































import {Component, Prop, Vue} from 'vue-property-decorator';
import '@/plugins/vxetable';

import {PageResultDto} from '@/framework/models/ResultDto';
import {
  MotionFrequencyDto,
  CreateOrUpdateMotionFrequencyDto,
  GetListMotionFrequencyDto
} from '@/framework/models/MotionFrequency';
import MotionFrequencyApi from '@/api/prescription/MotionFrequency';

@Component({})
export default class MotionFrequencyIndex extends Vue {
  private api = new MotionFrequencyApi();
  private listQuery = new GetListMotionFrequencyDto();
  private tableData: PageResultDto<MotionFrequencyDto> = {
    items: new Array<MotionFrequencyDto>(),
    totalCount: 0
  };

  private rules = {
    frequency: [{
      required: true,
      message: '频率(天/周)不能为空',
      trigger: 'blur'
    }],
  }

  public async handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      const $table = this.$refs.dataTable as any
      await $table.remove(row)
      if (row.id) {
        await this.api.DeleteAsync(row.id);
        await this.getList();
        this.$notify({
          message: '删除成功',
          type: 'success',
          title: ''
        });
      }
    }).catch(() => {
    });
  }

  public async saveEvent(row: any) {
    const $table = this.$refs.dataTable as any
    const errMap = await $table.validate().catch((errMap: any) => errMap)
    if (!errMap) {
      $table.clearActived().then(() => {
        if ($table.isUpdateByRow(row)) {
          this.editing(row)
        } else if (!row.id) {
          this.creating(row)
        }
      })
    }
  }

  private async activated() {
    await this.getList();
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private async handleCreate() {
    await this.insertEvent()
  }

  private async insertEvent(row ?: any) {
    const $table = this.$refs.dataTable as any
    const insertRecords = $table.getInsertRecords()
    if (insertRecords.length <= 0) {
      const record = {}
      const {
        row: newRow
      } = await $table.insertAt(record, row)
      await $table.setActiveRow(newRow)
    }
  }

  private cancelRowEvent(row: any) {
    const $table = this.$refs.dataTable as any
    $table.clearActived().then(() => {
      // 还原行数据
      $table.revertData(row)
      // TODO 如果是新添加的行，需要删除该行
      if (!row.id) {
        $table.remove(row)
      }
    })
  }

  private updateHandle(row: any) {
    const $table = this.$refs.dataTable as any
    const insertRecords = $table.getInsertRecords()
    if (insertRecords.length <= 0) {
      $table.setActiveRow(row)
    }
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList()
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList()
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }
}
