import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface MotionFrequencyDto extends ExtensibleEntityDto <Guid> {
  frequency: number
}

export class CreateOrUpdateMotionFrequencyDto {
  public frequency: number | undefined
}

export class GetListMotionFrequencyDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
